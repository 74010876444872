var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;
var _classCallCheck2 = _interopRequireDefault(require("@babel/runtime/helpers/classCallCheck"));
var _createClass2 = _interopRequireDefault(require("@babel/runtime/helpers/createClass"));
var _color = _interopRequireDefault(require("color"));
var _reactNativeWeb = require("../../../../upmesh-support/node_modules/react-native-web");
var Styles = exports.Styles = function () {
  function Styles() {
    (0, _classCallCheck2.default)(this, Styles);
    this.absoluteContentAdjustStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: 0
    };
    this.absoluteStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: 0
    };
    this.activeBgColor = 'rgba(33,150,243,1)';
    this.appBgColor = '#FFFFFF';
    this.appBgColorDarken = '#CCCCCC';
    this.backgroundImage = {
      flex: 1,
      resizeMode: 'cover',
      backgroundColor: this.appBgColor
    };
    this.black38 = 'rgba(0,0,0,0.38)';
    this.black42 = 'rgba(0,0,0,0.42)';
    this.black54 = 'rgba(0,0,0,0.54)';
    this.black87 = 'rgba(0,0,0,0.87)';
    this.borderColor = 'rgba(0,0,0,0.12)';
    this.borderColorActive = '#2196f3';
    this.borderRadius = 6;
    this.borderWidth = 1;
    this.brandDanger = '#F44336';
    this.brandDark = '#252932';
    this.brandInfo = '#03A9F4';
    this.brandLight = '#EEEEEE';
    this.brandPrimary = '#0073FF';
    this.brandSecondary = '#00CD9E';
    this.brandSidebar = '#252932';
    this.brandSuccess = '#4CAF50';
    this.brandWarning = '#ff9800';
    this.button = {
      height: 36,
      backgroundColor: 'transparent',
      flexDirection: 'row',
      padding: 0,
      alignItems: 'center',
      justifyContent: 'center',
      alignSelf: 'stretch',
      overflow: 'visible'
    };
    this.buttonBackground = {
      height: 36,
      backgroundColor: 'transparent',
      padding: 0,
      overflow: 'visible',
      justifyContent: 'center',
      alignSelf: 'stretch',
      alignItems: 'stretch'
    };
    this.buttonDisabled = {
      opacity: 0.5
    };
    this.buttonOuter = {
      height: 48,
      padding: 6,
      minWidth: 88,
      justifyContent: 'center',
      overflow: 'visible'
    };
    this.buttonTextDisabled = {
      color: 'rgba(0,0,0,0.26)'
    };
    this.buttonTouchTarget = {
      height: 48,
      padding: 0,
      justifyContent: 'center',
      width: '100%',
      alignItems: 'stretch',
      overflow: 'visible'
    };
    this.cardBackgroundColor = '#EEEEEE';
    this.cardDefaultBg = '#fff';
    this.chipDefaultBg = '#EBEBEB';
    this.contentHeaderColor = 'rgba(255,255,255,1)';
    this.contentHeaderIconColor = 'rgba(174,174,174,1)';
    this.datePickerClockDefaultBg = '#EBEBEB';
    this.datePickerColor = '#009688';
    this.datePickerTextColor = '#FFFFFF';
    this.defaultButtonTextColor = '#FFFFFF';
    this.defaultIconColor = 'rgba(0,0,0,0.54)';
    this.maxDialogWidth = 512;
    this.dialogBackground = {
      backgroundColor: '#FFFFFF',
      padding: 0,
      borderRadius: this.borderRadius
    };
    this.dialogContainer = {
      flex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: 0,
      margin: 0,
      zIndex: 24
    };
    this.dialogContainerBox = {
      backgroundColor: '#FFFFFF',
      padding: 0,
      width: '90%',
      maxWidth: 560,
      height: 'auto',
      maxHeight: '100%',
      borderRadius: this.borderRadius,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'transparent'
    };
    this.dialogHidden = {
      top: -10000,
      left: 0,
      height: 0,
      width: 0
    };
    this.disabledColor = '#9E9E9E';
    this.dropFileOverlayColor = 'rgba(0,115,255,0.64)';
    this.dropFileTextColor = 'rgba(255,255,255,1)';
    this.fontFamily = 'Roboto';
    this.fontWeightBold = '700';
    this.fontWeightLight = '300';
    this.fontWeightMedium = '500';
    this.fontWeightRegular = '400';
    this.formInputFilledBackgroundColor = '#f5f5f5';
    this.headerBG = 'rgba(255,255,255,1)';
    this.headerBGDarken = '#FAFAFA';
    this.headerButtonColor = '#252932';
    this.headerTitleColor = this.black87;
    this.hintTextColor = 'rgba(0,0,0,0.38)';
    this.homeButtonTarget = '/';
    this.imageGalerySpaceBetween = 8;
    this.lightBoxOverlayOpacity = 1;
    this.lightBoxOverlaycolor = '#000000';
    this.lisItemColor = '#FFFFFF';
    this.lisItemHoverColor = (0, _color.default)(this.lisItemColor).darken(0.2).toString();
    this.lisItemRippleColor = 'rgb(0, 0, 0)';
    this.lisItemSelectedColor = (0, _color.default)(this.lisItemColor).darken(0.3).toString();
    this.loadingOverlayColor = 'rgba(0,0,0,0.54)';
    this.loadingTextColor = 'rgba(255,255,255,1)';
    this.materialTextHoverColor = 'rgba(246, 246, 248, 0.50)';
    this.menuBgColor = 'rgba(34,37,38,0.7)';
    this.menuButtonColor = '#FFFFFF';
    this.menuButtonSelected = '#CCCCCC';
    this.overlay = {
      flex: 1,
      top: 0,
      left: 0,
      position: 'absolute'
    };
    this.overlayColor = 'rgba(47,49,49,0.87)';
    this.overlaysStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      padding: 0,
      backgroundColor: 'transparent'
    };
    this.popUpBackground = {
      backgroundColor: '#FFFFFF',
      padding: 0
    };
    this.popUpContainer = {
      flex: 1,
      position: 'absolute',
      top: 0,
      left: 0,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      margin: 0
    };
    this.popUpHidden = {
      top: -10000,
      left: 0,
      height: 0,
      width: 0
    };
    this.primaryTextColor = 'rgba(0,0,0,0.87)';
    this.progressBarColor = '#00CC9C';
    this.searchBarBackground = 'rgba(0,0,0,0.06)';
    this.secondaryTextColor = 'rgba(0,0,0,0.54)';
    this.statusBarHeight = 0;
    this.statusBarTopPadding = 0;
    this.statusbarStyle = 'dark-content';
    this.toastContainer = {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      justifyContent: 'center',
      alignItems: 'center',
      padding: 0,
      margin: 0
    };
    this.toastDefaultBg = '#323232';
    this.toastDefaultTextColor = '#FFFFFF';
    this.transBackground = {
      backgroundColor: 'rgba(255,255,255,0.85)'
    };
    this.white38 = 'rgba(255,255,255,0.38)';
    this.white54 = 'rgba(255,255,255,0.54)';
    this.white87 = 'rgba(255,255,255,0.87)';
    this.windowHeight = 0;
    this.windowWidth = 0;
  }
  return (0, _createClass2.default)(Styles, [{
    key: "Body1",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: 0.5,
        fontSize: this.getScreenRelativePixelSize(16),
        fontWeight: this.fontWeightRegular
      };
    }
  }, {
    key: "Body2",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: 0.25,
        fontSize: this.getScreenRelativePixelSize(14),
        fontWeight: this.fontWeightRegular
      };
    }
  }, {
    key: "Button",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: 0.75,
        fontSize: this.getScreenRelativePixelSize(14),
        fontWeight: this.fontWeightMedium
      };
    }
  }, {
    key: "Caption",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: 0.4,
        fontSize: this.getScreenRelativePixelSize(12),
        fontWeight: this.fontWeightRegular
      };
    }
  }, {
    key: "H1",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: -1.5,
        fontSize: this.getScreenRelativePixelSize(96),
        fontWeight: this.fontWeightLight
      };
    }
  }, {
    key: "H2",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: -0.5,
        fontSize: 60,
        fontWeight: this.fontWeightLight
      };
    }
  }, {
    key: "H3",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: 0,
        fontSize: this.getScreenRelativePixelSize(48),
        fontWeight: this.fontWeightRegular
      };
    }
  }, {
    key: "H4",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: 0.25,
        fontSize: this.getScreenRelativePixelSize(34),
        fontWeight: this.fontWeightRegular
      };
    }
  }, {
    key: "H5",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: 0,
        fontSize: this.getScreenRelativePixelSize(24),
        fontWeight: this.fontWeightRegular
      };
    }
  }, {
    key: "H6",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: 0.15,
        fontSize: this.getScreenRelativePixelSize(20),
        fontWeight: this.fontWeightMedium
      };
    }
  }, {
    key: "Overline",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: 1.5,
        fontSize: this.getScreenRelativePixelSize(10),
        fontWeight: this.fontWeightRegular
      };
    }
  }, {
    key: "Subtitle1",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: 0.15,
        fontSize: this.getScreenRelativePixelSize(16),
        fontWeight: this.fontWeightRegular
      };
    }
  }, {
    key: "Subtitle2",
    get: function get() {
      return {
        color: this.defaultTextColor,
        letterSpacing: 0.1,
        fontSize: this.getScreenRelativePixelSize(14),
        fontWeight: this.fontWeightMedium
      };
    }
  }, {
    key: "TexInputOld",
    get: function get() {
      return {
        color: this.defaultTextColor,
        fontSize: 16
      };
    }
  }, {
    key: "alertButtonStyle",
    get: function get() {
      return {
        color: this.brandPrimary
      };
    }
  }, {
    key: "appBarContentPaddingValue",
    get: function get() {
      return this.getScreenRelativePixelSize(16);
    }
  }, {
    key: "basename",
    get: function get() {
      var _process$env;
      if (this._basename != null) {
        return this._basename;
      }
      if (process != null && ((_process$env = process.env) == null ? void 0 : _process$env.BASENAME) != null) {
        return process.env.BASENAME;
      }
      return '/';
    },
    set: function set(basename) {
      this._basename = basename;
    }
  }, {
    key: "borderStyle",
    get: function get() {
      return {
        borderWidth: this.borderWidth,
        borderColor: this.borderColor,
        borderStyle: 'solid'
      };
    }
  }, {
    key: "layoutRuleBorderStyle",
    get: function get() {
      return {
        borderWidth: this.borderWidth,
        borderColor: '#8fd3fe'
      };
    }
  }, {
    key: "bottomBorder",
    get: function get() {
      return {
        alignSelf: 'stretch',
        borderColor: this.borderColor,
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: 1
      };
    }
  }, {
    key: "breakpointL",
    get: function get() {
      return 1280;
    }
  }, {
    key: "breakpointM",
    get: function get() {
      return 840;
    }
  }, {
    key: "buttonRaised",
    get: function get() {
      return this.elevation2;
    }
  }, {
    key: "contentPadding",
    get: function get() {
      return {
        padding: this.contentPaddingValue
      };
    }
  }, {
    key: "contentPaddingValue",
    get: function get() {
      return this.getScreenRelativePixelSize(24);
    }
  }, {
    key: "contentStyle",
    get: function get() {
      return Object.assign({}, this.contentPadding, {
        alignItems: 'center',
        alignSelf: 'stretch'
      });
    }
  }, {
    key: "darkenHeader",
    get: function get() {
      return (0, _color.default)(this.headerBG).darken(0.03).string();
    }
  }, {
    key: "defaultTextColor",
    get: function get() {
      return this.primaryTextColor;
    }
  }, {
    key: "defaultTextColorLighten",
    get: function get() {
      return this.secondaryTextColor;
    }
  }, {
    key: "desktopMultiplicatorSize",
    get: function get() {
      return 1;
    }
  }, {
    key: "dialogModalBackground",
    get: function get() {
      var styles = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        justifyContent: 'center',
        alignItems: 'center'
      };
      var platformSpecificStyles = _reactNativeWeb.Platform.OS === 'android' ? {
        elevation: 24,
        zIndex: 24
      } : {
        zIndex: 24
      };
      return Object.assign({}, styles, platformSpecificStyles);
    }
  }, {
    key: "elevation1",
    get: function get() {
      var styles;
      if (_reactNativeWeb.Platform.OS === 'android') {
        styles = {
          elevation: 1,
          zIndex: 1
        };
      } else {
        styles = {
          zIndex: 1,
          shadowColor: '#000000',
          shadowOpacity: 0.26,
          shadowRadius: 0.5,
          shadowOffset: {
            width: 0,
            height: 1
          }
        };
      }
      return _reactNativeWeb.StyleSheet.flatten(styles);
    }
  }, {
    key: "elevation12",
    get: function get() {
      var styles;
      if (_reactNativeWeb.Platform.OS === 'android') {
        styles = {
          elevation: 12,
          zIndex: 12
        };
      } else {
        styles = {
          zIndex: 12,
          shadowColor: '#000000',
          shadowOpacity: 0.26,
          shadowRadius: 8,
          shadowOffset: {
            width: 0,
            height: 15
          }
        };
      }
      return _reactNativeWeb.StyleSheet.flatten(styles);
    }
  }, {
    key: "elevation16",
    get: function get() {
      var styles;
      if (_reactNativeWeb.Platform.OS === 'android') {
        styles = {
          elevation: 16,
          zIndex: 16
        };
      } else {
        styles = {
          zIndex: 16,
          shadowColor: '#000000',
          shadowOpacity: 0.26,
          shadowRadius: 8,
          shadowOffset: {
            width: 0,
            height: 15
          }
        };
      }
      return _reactNativeWeb.StyleSheet.flatten(styles);
    }
  }, {
    key: "elevation2",
    get: function get() {
      var styles;
      if (_reactNativeWeb.Platform.OS === 'android') {
        styles = {
          elevation: 2,
          zIndex: 2
        };
      } else {
        styles = {
          zIndex: 2,
          shadowColor: '#000000',
          shadowOpacity: 0.26,
          shadowRadius: _reactNativeWeb.Platform.OS !== 'ios' ? 6 : 4,
          shadowOffset: {
            width: 0,
            height: 2
          }
        };
      }
      return _reactNativeWeb.StyleSheet.flatten(styles);
    }
  }, {
    key: "elevation24",
    get: function get() {
      var styles;
      if (_reactNativeWeb.Platform.OS === 'android') {
        styles = {
          elevation: 24,
          zIndex: 24
        };
      } else {
        styles = {
          zIndex: 24,
          shadowColor: '#000000',
          shadowOpacity: 0.26,
          shadowRadius: 12,
          shadowOffset: {
            width: 0,
            height: 22
          }
        };
      }
      return _reactNativeWeb.StyleSheet.flatten(styles);
    }
  }, {
    key: "elevation3",
    get: function get() {
      var styles;
      if (_reactNativeWeb.Platform.OS === 'android') {
        styles = {
          elevation: 3,
          zIndex: 3
        };
      } else {
        styles = {
          zIndex: 3,
          shadowColor: '#000000',
          shadowOpacity: 0.26,
          shadowRadius: 1.5,
          shadowOffset: {
            width: 0,
            height: 3
          }
        };
      }
      return _reactNativeWeb.StyleSheet.flatten(styles);
    }
  }, {
    key: "elevation4",
    get: function get() {
      var styles;
      if (_reactNativeWeb.Platform.OS === 'android') {
        styles = {
          elevation: 4,
          zIndex: 4
        };
      } else {
        styles = {
          zIndex: 4,
          shadowColor: '#000000',
          shadowOpacity: 0.1,
          shadowRadius: 10,
          shadowOffset: {
            width: 0,
            height: 2
          }
        };
      }
      return _reactNativeWeb.StyleSheet.flatten(styles);
    }
  }, {
    key: "elevation6",
    get: function get() {
      var styles;
      if (_reactNativeWeb.Platform.OS === 'android') {
        styles = {
          elevation: 6,
          zIndex: 6
        };
      } else {
        styles = {
          zIndex: 6,
          shadowColor: '#000000',
          shadowOpacity: 0.26,
          shadowRadius: 3,
          shadowOffset: {
            width: 0,
            height: 5.5
          }
        };
      }
      return _reactNativeWeb.StyleSheet.flatten(styles);
    }
  }, {
    key: "elevation8",
    get: function get() {
      var styles;
      if (_reactNativeWeb.Platform.OS === 'android') {
        styles = {
          elevation: 8,
          zIndex: 8
        };
      } else {
        styles = {
          zIndex: 8,
          shadowColor: '#000000',
          shadowOpacity: 0.26,
          shadowRadius: 7,
          shadowOffset: {
            width: 0,
            height: 4
          }
        };
      }
      return _reactNativeWeb.StyleSheet.flatten(styles);
    }
  }, {
    key: "elevation9",
    get: function get() {
      var styles;
      if (_reactNativeWeb.Platform.OS === 'android') {
        styles = {
          elevation: 9,
          zIndex: 9
        };
      } else {
        styles = {
          zIndex: 9,
          shadowColor: '#000000',
          shadowOpacity: 0.26,
          shadowRadius: 8,
          shadowOffset: {
            width: 0,
            height: 4.5
          }
        };
      }
      return _reactNativeWeb.StyleSheet.flatten(styles);
    }
  }, {
    key: "footerHeight",
    get: function get() {
      return this.getScreenRelativePixelSize(48);
    }
  }, {
    key: "formIcon",
    get: function get() {
      return {
        position: 'absolute',
        top: 36,
        left: 0,
        color: this.defaultIconColor,
        width: 24,
        height: 24,
        alignSelf: 'center',
        justifyContent: 'center'
      };
    }
  }, {
    key: "formIconRight",
    get: function get() {
      return {
        alignSelf: 'center',
        width: 36,
        height: 24,
        marginLeft: 8,
        justifyContent: 'center',
        marginBottom: 0
      };
    }
  }, {
    key: "formLine",
    get: function get() {
      return {
        flexDirection: 'row',
        flexGrow: 1,
        flexShrink: 1,
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: this.borderWidth,
        borderColor: this.black42,
        marginTop: 0,
        paddingTop: 0,
        paddingLeft: 0,
        paddingBottom: 8 - this.borderWidth,
        justifyContent: 'center',
        alignItems: 'center'
      };
    }
  }, {
    key: "headerHeight",
    get: function get() {
      return this.getScreenRelativePixelSize(48);
    }
  }, {
    key: "headerHeightBig",
    get: function get() {
      return 'auto';
    }
  }, {
    key: "isSmallScreen",
    get: function get() {
      if (this._isSmallScreen == null) {
        this.createIsSmallScreen();
      }
      return this._isSmallScreen;
    }
  }, {
    key: "loadingBackground",
    get: function get() {
      var styles = {
        backgroundColor: 'transparent',
        justifyContent: 'center',
        alignItems: 'center'
      };
      var platformSpecificStyles = _reactNativeWeb.Platform.OS === 'android' ? {
        elevation: 30,
        zIndex: 30
      } : {};
      return Object.assign({}, styles, platformSpecificStyles);
    }
  }, {
    key: "minSwipeValue",
    get: function get() {
      return this.getScreenRelativePixelSize(24);
    }
  }, {
    key: "modalBackground",
    get: function get() {
      var styles = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        padding: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        justifyContent: 'center',
        alignItems: 'center'
      };
      var platformSpecificStyles = _reactNativeWeb.Platform.OS === 'android' ? {
        elevation: 29,
        zIndex: 29
      } : {
        zIndex: 29
      };
      return Object.assign({}, styles, platformSpecificStyles);
    }
  }, {
    key: "popupHeader",
    get: function get() {
      return {
        backgroundColor: this.headerBG,
        borderStyle: 'solid',
        borderWidth: 0,
        borderBottomWidth: this.borderWidth,
        alignSelf: 'stretch',
        height: this.headerHeight,
        overflow: 'hidden',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center'
      };
    }
  }, {
    key: "statusBarColor",
    get: function get() {
      return (0, _color.default)(this.headerBG).darken(0.2).string();
    }
  }, {
    key: "text",
    get: function get() {
      return {
        textAlign: 'left',
        alignSelf: 'flex-start',
        justifyContent: 'flex-start',
        fontFamily: this.fontFamily,
        color: this.defaultTextColor
      };
    }
  }, {
    key: "tooltipOuter",
    get: function get() {
      return {
        position: 'absolute',
        width: 100,
        left: 0,
        top: 0,
        height: 32,
        backgroundColor: '#616161',
        opacity: 0.9,
        alignSelf: 'flex-start',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        borderRadius: 2,
        paddingLeft: 8,
        paddingRight: 8,
        overflow: 'visible',
        zIndex: 100
      };
    }
  }, {
    key: "tooltipText",
    get: function get() {
      return {
        position: 'absolute',
        alignSelf: 'flex-start',
        fontSize: this.windowWidth > this.breakpointL ? 10 : 11,
        color: '#ffffff',
        fontWeight: this.fontWeightMedium,
        marginVertical: 'auto'
      };
    }
  }, {
    key: "formElement",
    value: function formElement(width) {
      return {
        width: width
      };
    }
  }, {
    key: "getDefaultHoverColor",
    value: function getDefaultHoverColor(forColor) {
      if (forColor == null) {
        return 'rgba(0,0,0,0.16)';
      }
      if (forColor === 'transparent' || (0, _color.default)(forColor).hex() === '0xffffff' || forColor.toLowerCase() === '#ffffff') {
        return 'rgba(0,0,0,0.04)';
      }
      return (0, _color.default)(forColor).alpha(0.08).toString();
    }
  }, {
    key: "getDefaultRippleColor",
    value: function getDefaultRippleColor(forColor) {
      if (forColor == null) {
        return 'rgba(0,0,0,0.48)';
      }
      if (forColor === 'transparent' || (0, _color.default)(forColor).hex() === '0xffffff') {
        return 'rgba(0,0,0,0.16)';
      }
      return (0, _color.default)(forColor).alpha(0.32).toString();
    }
  }, {
    key: "getTextInputFontSize",
    value: function getTextInputFontSize() {
      var pixel = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 16;
      return pixel / _reactNativeWeb.PixelRatio.getFontScale();
    }
  }, {
    key: "getScreenRelativePixelSize",
    value: function getScreenRelativePixelSize(pixel) {
      if (pixel == null) return 0;
      return pixel;
    }
  }, {
    key: "createIsSmallScreen",
    value: function createIsSmallScreen() {
      this._isSmallScreen = _reactNativeWeb.Dimensions.get('screen').width < 1000;
    }
  }]);
}();